import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p className="home-about-body">
            i'm <b className="purple"> OMAR KONBOR </b> skilled <b className="purple"> Web Developer and Software Engineer </b> driven by a fervent passion for seizing learning opportunities and thriving amidst ever-changing environments. With a rich portfolio of over <b className="purple"> 1000+</b> hours in freelance work, I've finely honed my craft in <b className="purple">JavaScript, Node.Js, React.Js, and Python</b> . My motivation stems from tackling intricate web development and design challenges that truly transform user experiences.
            <br />
            <br />throughout my journey, I've showcased my versatility in handling various programming languages and have become a maestro of <b className="purple"> Git/Github version control </b>Git/Github version control. My expertise extends seamlessly across both server-side and front-end software development, and I'm known for delivering impeccable issue tracking and top-tier customer support. Moreover, I've actively participated in launching <b className="purple"> NFTs and token sales </b>, crafting time-based rules for system and <b className="purple">MySQL backups </b>, and deftly managing maintenance tasks on administrative Linux servers.
            <br />
            <br />
            Yet, my capabilities don't stop at technical prowess – I also possess remarkable  <b className="purple"> leadership traits</b>. Over time, I've skillfully  <b className="purple"> guided, mentored, and nurtured fellow programmers </b>, valuing collaboration and relishing the chance to enact meaningful change in the realm of web development. Equipped with a Dual  <b className="purple"> Bachelor's Degree in Internet Technology </b>and a  <b className="purple"> Master's Degree in Computer Methods Applied To Business Management </b>, I'm more than ready to embrace novel challenges in the ever-evolving tech landscape.
            <br />
            <br />
            Fluent in <b className="purple">English</b>  with an advanced proficiency, I approach each project with an unwavering commitment to <b className="purple">continuous learning</b>  and an <b className="purple">unrelenting problem-solving mindset</b> . <b className="purple">My adaptability </b> is a core strength, and I eagerly look forward to making impactful contributions to any project or team I become a part of.
          </p>

          <p style={{ color: "rgb(155 126 172)" }}>
            "Strive to build things that make a difference!"{" "}
          </p>
          <footer className="blockquote-footer">OMAR.K</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
