import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import Card from "react-bootstrap/Card";
import leaf from "../../Assets/Projects/leaf.png";
import emotion from "../../Assets/Projects/emotion.png";
import editor from "../../Assets/Projects/codeEditor.png";
import chatify from "../../Assets/Projects/chatify.png";
import suicide from "../../Assets/Projects/suicide.png";
import bitsOfCode from "../../Assets/Projects/blog.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>

          <Card className="project-card-view">
            <Card.Body>

              <iframe width="560" height="315" src="https://www.youtube.com/embed/OeT0Aotr04c" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

            </Card.Body>
          </Card>

          <Col md={4} className="project-card">
            <ProjectCard
              isBlog={false}
              title="Project SIide Deck"
              description=""
              ghLink=""
              demoLink="https://drive.google.com/file/d/13DrZLWEghD5OE5qB_1sKyP4Y4AecB_2q/view?usp=share_link"
            />
          </Col>


        </Row>
      </Container>
    </Container >
  );
}

export default Projects;
