import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Card from "react-bootstrap/Card";
import pitchVid from "../../Assets/pitch.mp4";

function Pitch() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Personal <strong className="purple">Elevator Pitch </strong>
        </h1>

        <Row className="justify-content-center align-items-center" style={{ height: "80vh" }}>
          <Col xs={12} md={8} lg={6}>
            <Card className="p-4">
              {/* Use the correct <video> element to display the video */}
              <video width="100%" controls>
                <source src={pitchVid} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </Card>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Pitch;
